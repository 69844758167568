<template>
    <v-container fluid>
        <PageLayout>
            <template v-if="false" v-slot:side>
                <v-icon color="primary" @click="filter_show===true ? filter_show=false : filter_show=true">mdi-filter-plus</v-icon>
                <v-list class="filter_left">
                    <v-list-group :ripple="false" v-model="group1" :group="'group1'" class="px-0">
                        <template v-slot:activator class="px-0">
                            <v-list-item-title :ripple="false" class="main_filter">{{$t('system_filters')}}</v-list-item-title>
                        </template>

                        <v-list-item :ripple="false" v-for="(item, i) in system_filters" :key="i" link>
                            <v-list-item-title class="cursor-pointer" @click="sendRoute(item.router)" v-text="item.title"></v-list-item-title>
                            <v-list-item-icon v-text="item.count" class="filter_count"></v-list-item-icon>
                        </v-list-item>
                    </v-list-group>
                </v-list>

                <v-list class="filter_left" v-if="Additional.length > 0">
                    <v-list-group :ripple="false" v-model="group2" :group="'group2'" class="px-0">
                        <template v-slot:activator class="px-0">
                            <v-list-item-title :ripple="false" class="main_filter">{{$t('additional')}}</v-list-item-title>
                        </template>
                        <v-list-item :ripple="false" v-for="(item, i) in Additional" :key="i" link>
                            <v-list-item-title v-text="item.title"></v-list-item-title>
                            <v-list-item-icon v-text="item.count" class="filter_count"></v-list-item-icon>
                        </v-list-item>
                    </v-list-group>
                </v-list>
            </template>
            <template>
                <TabsNav />
                <v-row>
                    <v-col cols="12" sm="10" class="mb-10">
                        <h1>{{ filter_show ? $t('search_tracking') : $t('menu_tracking') }}</h1>
                    </v-col>
                    <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="2" v-show="!filter_show" class="text-right">
                        <v-btn @click="addTracking"  class="infinity_button" color="secondary">
                            {{ $t('create') }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-form @submit.prevent="getTrackings()">
                    <v-btn v-show="$vuetify.breakpoint.xsOnly" @click="openDialogFile" color="indigo" fab fixed top right class="v-btn--add-form-top white--text">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                        <div>
                            <div class="py-0">
                                <v-row v-if="filter_show">
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="number" v-slot="{ errors, valid }">
                                            <v-text-field v-model="number" type="text" :error-messages="errors"
                                                          :label="$t('container_number')"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          :disabled="loading"
                                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                          clearable></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="application_number" rules="min:2|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="application_number" type="text" :error-messages="errors"
                                                          :label="$t('application_number')"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          :disabled="loading"
                                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                          clearable></v-text-field>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="platform_number" v-slot="{ errors, valid }">
                                            <v-text-field v-model="platform_number" type="text" :error-messages="errors"
                                                          :label="$t('platform_number')"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          :disabled="loading"
                                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                          clearable></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="platform_number_knr" rules="min:1|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="platform_number_knr" type="text" :error-messages="errors"
                                                          :label="$t('platform_number_knr')"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          :disabled="loading"
                                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                          clearable></v-text-field>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col class="py-0" cols="12" sm="6">
                                        <v-menu v-model="menuDateOfDeploymentFrom" :close-on-content-click="false" :nudge-right="40"
                                                transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <ValidationProvider ref="dateOperationFrom" name="date_operation_from" rules="date_format"
                                                                    v-slot="{ errors, valid }">
                                                    <v-text-field v-model="dateOperationFrom" :error-messages="errors"
                                                                  :label="$t('date_operation_from')"
                                                                  readonly v-on="on"
                                                                  @click:clear="dateOperationFrom = null"
                                                                  outlined
                                                                  solo
                                                                  flat
                                                                  dense
                                                                  :disabled="loading"
                                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                  color="primary"
                                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                  clearable></v-text-field>
                                                </ValidationProvider>
                                            </template>
                                            <v-date-picker ref="picker" v-model="dateOperationFrom"
                                                           :max="dateOperationTo ? $moment(dateOperationTo).format('YYYY-MM-DD') : $moment().format('YYYY-MM-DD')"
                                                           :locale="lang" @change="menuDateOfDeploymentFrom = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <v-menu v-model="menuDateOfDeploymentTo" :close-on-content-click="false" :nudge-right="40"
                                                transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <ValidationProvider ref="dateOperationTo" name="date_operation_to" rules="date_format"
                                                                    v-slot="{ errors, valid }">
                                                    <v-text-field v-model="dateOperationTo" :error-messages="errors"
                                                                  :label="$t('date_operation_to')"
                                                                  readonly v-on="on"
                                                                  @click:clear="dateOperationTo = null"
                                                                  outlined
                                                                  solo
                                                                  flat
                                                                  dense
                                                                  :disabled="loading"
                                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                  color="primary"
                                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                  clearable></v-text-field>
                                                </ValidationProvider>
                                            </template>
                                            <v-date-picker ref="picker" v-model="dateOperationTo"
                                                           :min="dateOperationFrom ? $moment(dateOperationFrom).format('YYYY-MM-DD') : $moment().format('YYYY-MM-DD')"
                                                           :locale="lang"
                                                           @change="menuDateOfDeploymentTo = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col class="py-0" cols="12" sm="6">
                                        <v-autocomplete v-model="status" :items="statusItems"
                                                        item-text="name" item-value="id"
                                                        :label="$t('status')"
                                                        outlined
                                                        solo
                                                        flat
                                                        dense
                                                        :disabled="loading"
                                                        :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                        color="primary"
                                                        class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                        clearable
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="company" rules="min:1"
                                                            v-slot="{ errors, valid }">
                                            <v-autocomplete v-model="company" :items="companyItems"
                                                            :error-messages="errors" :error="!valid"
                                                            :search-input.sync="companySearching"
                                                            item-text="name" item-value="id"
                                                            :loading="loadingCompanies"
                                                            :no-data-text="companySearching ? $t('nothing_found_by',{'search': companySearching}) : $t('nothing_found_name')"
                                                            :label="$t('company')"
                                                            @click:clear="companyItems=[]"
                                                            @click="clearCompanies"
                                                            @click:append="redirectToCompany(company)"

                                                            clearable autocomplete="off"
                                                            outlined
                                                            solo
                                                            flat
                                                            dense
                                                            :disabled="loading"
                                                            :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                            color="primary"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text">
                                                <template v-slot:item="{ attrs, item, parent, selected }">
                                                    <span class="search-list">
                                                        <span v-html="item.name"></span>
                                                        <span class="ml-2 hidden-is-empty"
                                                              v-text="item.bin_iin"></span>
                                                    </span>
                                                </template>
                                                <template v-slot:selection="{ attrs, item, parent, selected }">
                                                    <span class="search-list">
                                                        <span v-html="item.name"></span>
                                                        <span class="ml-2 hidden-is-empty"
                                                              v-text="item.bin_iin"></span>
                                                    </span>
                                                </template>
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                                <v-row v-if="filterShow ">

                                    <v-col class="py-0" cols="12" sm="6">
                                        <v-autocomplete v-model="type_container" :items="typeContainerItems"
                                                        item-text="name" item-value="id"
                                                        :label="$t('type_container_name')"
                                                        outlined
                                                        solo
                                                        flat
                                                        dense
                                                        :disabled="loading"
                                                        :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                        color="primary"
                                                        class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                        clearable></v-autocomplete>

                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <v-autocomplete v-model="delivered" :items="deliveredItems"
                                                        item-text="name" item-value="id"
                                                        :label="$t('delivered')"
                                                        outlined
                                                        solo
                                                        flat
                                                        dense
                                                        :disabled="loading"
                                                        :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                        color="primary"
                                                        class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                        clearable></v-autocomplete>
                                    </v-col>

                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="status" v-slot="{ errors, valid }">
                                            <v-autocomplete v-model="departure" :items="departureItems"
                                                            :error="!valid" :error-messages="errors"
                                                            :search-input.sync="departureSearching"
                                                            item-text="name" item-value="id"
                                                            :loading="loadingDepartures"
                                                            @click="clearDepartures"
                                                            :no-data-text="departureSearching ? $t('nothing_found_by',{'search': departureSearching}) : $t('nothing_found_departures')"
                                                            :label="$t('departures')" @click:clear="departureItems=[]"
                                                            return-object  autocomplete="off"
                                                            outlined
                                                            solo
                                                            flat
                                                            dense
                                                            :disabled="loading"
                                                            :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                            color="primary"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                            clearable>
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="destination" v-slot="{ errors, valid }">
                                            <v-autocomplete v-model="destination" :items="destinationItems"
                                                            :error="!valid" :error-messages="errors"
                                                            :search-input.sync="destinationSearching"
                                                            item-text="name" item-value="id"
                                                            :loading="loadingDestination"
                                                            @click="clearDestination"
                                                            :no-data-text="destinationSearching ? $t('nothing_found_by',{'search': destinationSearching}) : $t('nothing_found_destination')"
                                                            :label="$t('destination')" @click:clear="destinationItems=[]"
                                                            return-object  autocomplete="off"
                                                            outlined
                                                            solo
                                                            flat
                                                            dense
                                                            :disabled="loading"
                                                            :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                            color="primary"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                            clearable>
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>



                                    <v-col class="py-0" cols="12" sm="6">
                                        <v-menu v-model="dateMenuFromGr" :close-on-content-click="false" :nudge-right="40"
                                                transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <ValidationProvider ref="dateFromGr" name="date_from_gr" rules="date_format"
                                                                    v-slot="{ errors, valid }">
                                                    <v-text-field v-model="dateFromGr" :error-messages="errors"
                                                                  :label="$t('date_of_arrival_at_the_border_from')"
                                                                  readonly v-on="on"
                                                                  @click:clear="dateFromGr = null"
                                                                  outlined
                                                                  solo
                                                                  flat
                                                                  dense
                                                                  :disabled="loading"
                                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                  color="primary"
                                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                  clearable></v-text-field>
                                                </ValidationProvider>
                                            </template>
                                            <v-date-picker ref="picker" v-model="dateFromGr"
                                                           :max="dateToGr ? $moment(dateToGr).format('YYYY-MM-DD') : $moment().format('YYYY-MM-DD')"
                                                           :locale="lang" @change="dateMenuFromGr = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <v-menu v-model="dateMenuToGr" :close-on-content-click="false" :nudge-right="40"
                                                transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <ValidationProvider ref="dateToGr" name="date_to_gr" rules="date_format"
                                                                    v-slot="{ errors, valid }">
                                                    <v-text-field v-model="dateToGr" :error-messages="errors"
                                                                  :label="$t('date_of_arrival_at_the_border_to')"
                                                                  readonly v-on="on"
                                                                  @click:clear="dateToGr = null"
                                                                  outlined
                                                                  solo
                                                                  flat
                                                                  dense
                                                                  :disabled="loading"
                                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                  color="primary"
                                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                  clearable></v-text-field>
                                                </ValidationProvider>
                                            </template>
                                            <v-date-picker ref="picker" v-model="dateToGr"
                                                           :min="dateFromGr ? $moment(dateFromGr).format('YYYY-MM-DD') : $moment().format('YYYY-MM-DD')"
                                                           :locale="lang"
                                                           @change="dateMenuToGr = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col class="py-0" cols="12" sm="6">
                                        <v-menu v-model="dateMenuFromPr" :close-on-content-click="false" :nudge-right="40"
                                                transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <ValidationProvider ref="dateFromPr" name="date_from_pr" rules="date_format"
                                                                    v-slot="{ errors, valid }">
                                                    <v-text-field v-model="dateFromPr" :error-messages="errors"
                                                                  :label="$t('arrival_date_at_destination_from')"
                                                                  readonly v-on="on"
                                                                  @click:clear="dateFromPr = null"
                                                                  outlined
                                                                  solo
                                                                  flat
                                                                  dense
                                                                  :disabled="loading"
                                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                  color="primary"
                                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                  clearable></v-text-field>
                                                </ValidationProvider>
                                            </template>
                                            <v-date-picker ref="picker" v-model="dateFromPr"
                                                           :max="dateToPr ? $moment(dateToPr).format('YYYY-MM-DD') : $moment().format('YYYY-MM-DD')"
                                                           :locale="lang" @change="dateMenuFromPr = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <v-menu v-model="dateMenuToPr" :close-on-content-click="false" :nudge-right="40"
                                                transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <ValidationProvider ref="dateToPr" name="date_to_pr" rules="date_format"
                                                                    v-slot="{ errors, valid }">
                                                    <v-text-field v-model="dateToPr" :error-messages="errors"
                                                                  :label="$t('arrival_date_at_destination_to')"
                                                                  readonly v-on="on"
                                                                  @click:clear="dateToPr = null"
                                                                  outlined
                                                                  solo
                                                                  flat
                                                                  dense
                                                                  :disabled="loading"
                                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                  color="primary"
                                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                  clearable></v-text-field>
                                                </ValidationProvider>
                                            </template>
                                            <v-date-picker ref="picker" v-model="dateToPr"
                                                           :min="dateFromPr ? $moment(dateFromPr).format('YYYY-MM-DD') : $moment().format('YYYY-MM-DD')"
                                                           :locale="lang"
                                                           @change="dateMenuToPr = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col class="py-0" cols="12" sm="6">
                                        <v-menu v-model="dateMenuFrom" :close-on-content-click="false" :nudge-right="40"
                                                transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <ValidationProvider ref="dateFrom" name="date_from" rules="date_format"
                                                                    v-slot="{ errors, valid }">
                                                    <v-text-field v-model="dateFrom" :error-messages="errors"
                                                                  :label="$t('date_of_exit_from_border_from')"
                                                                  readonly v-on="on"
                                                                  @click:clear="dateFrom = null"
                                                                  outlined
                                                                  solo
                                                                  flat
                                                                  dense
                                                                  :disabled="loading"
                                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                  color="primary"
                                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                  clearable></v-text-field>
                                                </ValidationProvider>
                                            </template>
                                            <v-date-picker ref="picker" v-model="dateFrom"
                                                           :max="dateTo ? $moment(dateTo).format('YYYY-MM-DD') : $moment().format('YYYY-MM-DD')"
                                                           :locale="lang" @change="dateMenuFrom = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <v-menu v-model="dateMenuTo" :close-on-content-click="false" :nudge-right="40"
                                                transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <ValidationProvider ref="dateTo" name="date_to" rules="date_format"
                                                                    v-slot="{ errors, valid }">
                                                    <v-text-field v-model="dateTo" :error-messages="errors"
                                                                  :label="$t('date_of_exit_from_border_to')"
                                                                  readonly v-on="on"
                                                                  @click:clear="dateTo = null"
                                                                  outlined
                                                                  solo
                                                                  flat
                                                                  dense
                                                                  :disabled="loading"
                                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                  color="primary"
                                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                  clearable></v-text-field>
                                                </ValidationProvider>
                                            </template>
                                            <v-date-picker ref="picker" v-model="dateTo"
                                                           :min="dateFrom ? $moment(dateFrom).format('YYYY-MM-DD') : $moment().format('YYYY-MM-DD')"
                                                           :locale="lang"
                                                           @change="dateMenuTo = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>




                                    <v-col class="py-0" cols="12" sm="6">
                                        <v-select :value="perPage" :items="perPageItems"
                                                  :label="$t('items_per_page')"
                                                  @input="perPage = options.itemsPerPage = Number($event)"
                                                  outlined
                                                  solo
                                                  flat
                                                  dense
                                                  :disabled="loading"
                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                                <v-row v-if="filter_show">
                                    <v-col cols="12" class="text-right" v-if="!filterShow"><span @click="filterShow=true" class="blue--text cursor-pointer">Расширенный поиск</span></v-col>
                                    <v-col cols="12" class="text-right" v-if="filterShow"><span @click="filterShow=false" class="blue--text cursor-pointer">Скрыть</span></v-col>
                                </v-row>
                            </div>

                            <div v-if="filter_show" class="px-4 py-4">
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-btn :block="$vuetify.breakpoint.xsOnly"
                                               color="primary"
                                               @click="UploadApplications">
                                            <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
                                            {{ $t('save_to_excel') }}
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" sm="6" class="text-right">
                                        <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly" color="primary">{{ $t('search') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </ValidationObserver>
                </v-form>
                <v-row>
                    <v-col cols="12" >

                        <v-row v-if="trackings.length > 0">
                            <v-col cols="12" class="my-0 py-0 pt-5">

                                    <v-card
                                        elevation="0"
                                        :hover="true"
                                        v-for="(item, i) in trackings"
                                        :key="'tracking_'+i"
                                        class="mb-7 rounded-lg"
                                        :class="($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly) ? 'text-center' :''"

                                        color="#F5F5F5"

                                    >

                                        <v-card-text class="px-0 py-0">

                                            <v-row class="px-5 pb-2">
                                                <v-col cols="12" sm="4" :class="$vuetify.breakpoint.xsOnly ? 'text-left deal_col_50' : ''">
                                                    <div class="mb-4 deal_title">
                                                        <div class="deal_text mb-1">{{ $t('date_created') }}</div>
                                                        <div @click="showDetailsApplication(item)">{{ item.date_operation ? $moment(item.date_operation).format('DD.MM.YYYY') : '' }}</div>
                                                    </div>
                                                    <div class="mb-2 deal_title">
                                                        <div class="deal_text mb-1">{{ $t('container_number') }}</div>
                                                        {{ item.container_number ? item.container_number : $t('no_data') }}
                                                    </div>
                                                </v-col>

                                                <v-col cols="12" sm="4" :class="$vuetify.breakpoint.xsOnly ? 'text-right deal_col_50' : ''">
                                                    <div class="mb-4 deal_title">
                                                        <div class="deal_text mb-1">{{ $t('direction') }}</div>
                                                        <div class="cursor-pointer" @click="editApplication(item)">
                                                            {{ item.departure }} - {{ item.destination }}
                                                        </div>

                                                    </div>
                                                    <div v-if="item.day > 0" class="deal_title">
                                                        <div class="deal_text mb-1">{{ $t('in_operation_for') }}</div>
                                                        {{ $t('for_day', {"day": item.day}) }}
                                                    </div>
                                                </v-col>

                                                <v-col cols="12" sm="4" :class="$vuetify.breakpoint.xsOnly ? 'text-left deal_col_50' : ''">
                                                    <div class="deal_title">
                                                        <div class=" deal_text">{{ $t('deal.self') }}</div>
                                                        <div class="cursor-pointer" @click="showDeal(item.deal_uuid)">{{ item.number_deal }}</div>

                                                    </div>
                                                    <div class="deal_title">
                                                        <div class=" deal_text">{{ $t('current_station') }}</div>
                                                        <div class="cursor-pointer" @click="showDialogTracking(item)">{{ item.station ? item.station :  $t('no') }}</div>

                                                    </div>
                                                </v-col>

                                                <v-col cols="12" v-if="($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly)">
                                                    <v-btn type="button"
                                                           @click="showDetailsApplication(item)"
                                                           outlined
                                                           color="primary"
                                                           class="rounded-lg but_enter"
                                                           block
                                                           :height="$vuetify.breakpoint.xsOnly ? 45 : 35">
                                                        {{ $t('details') }}
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="12" class="pb-7" v-if="!($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly)">
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <ul class="pod_status d-flex">
                                                                <template v-for="(status_item, i) in item.trackings">
                                                                    <li :key="'status'+status_item.id" class="li1">
                                                                        <div class="node " :class="(item.cargo_arrived === 0 && (i + 1) === item.trackings.length)   ? 'grey_point' : 'blue_point'"></div>
                                                                        <p

                                                                            :class="(i+1) === item.trackings.length ? 'li_last' : ''"><span :class="(item.cargo_arrived === 0 && (i + 1) === item.trackings.length)   ? 'grey_color' : 'blue_color'">{{ status_item.client_name }}</span>
                                                                        </p>
                                                                    </li>
                                                                    <li :key="'status'+status_item.id + '-' + status_item.id" v-if="(i+1) !== item.trackings.length" class="my_divider "

                                                                        :style="'width:' + (100 / (item.trackings.length-1)) + '% !important'"

                                                                        :class="(item.cargo_arrived === 0 && (i + 2) === item.trackings.length)   ? 'grey_line' : 'blue_line'">
                                                                    </li>
                                                                </template>
                                                            </ul>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>

                            </v-col>
                        </v-row>

                    </v-col>
                </v-row>


                <v-pagination v-model="page" @input="getTrackings()" :total-visible="totalVisiblePag" class="pagination_table" :length="pageCount" :disabled="loading"></v-pagination>
            </template>
        </PageLayout>

        <v-dialog v-if="dialogDetails" v-model="dialogDetails" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title>{{ $t('details') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="cancelDetails()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text class="pa-0">
                    <v-row>
                        <v-col cols="12" sm="6" >
                            <v-col cols="12" class="py-1 text-right" v-if="(detailsItems.client !== null && typeof detailsItems.client.name !== null)"><b> Клиент:</b> {{ detailsItems.client.name ? detailsItems.client.name :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1 text-right"><b> {{$t('application_number')}}:</b> {{ detailsItems.deal.number ? detailsItems.deal.number :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1 text-right"><b> {{$t('container_number')}}:</b> {{ detailsItems.container_number ? detailsItems.container_number :  $t('no_data')  }}</v-col>

                            <v-col cols="12" class="py-1 text-right"><b> {{$t('departures')}}:</b> {{ detailsItems.departure.name ? detailsItems.departure.name :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1 text-right"><b> {{$t('destination')}}:</b> {{ detailsItems.destination.name ? detailsItems.destination.name :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1 text-right"><b> {{$t('type_container')}}:</b> {{ detailsItems.type_container.name ? detailsItems.type_container.name :  $t('no_data')  }}</v-col>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-col cols="12" class="py-1"><b> {{$t('status')}}:</b> {{ detailsItems.status.name ? detailsItems.status.name :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b> {{$t('application_date')}}:</b> {{ detailsItems.created_at ? $moment(detailsItems.created_at).format('YYYY-MM-DD HH:MM') : $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b> {{$t('date_operation')}}:</b> {{ detailsItems.date_operation  ? $moment(detailsItems.date_operation).format('YYYY-MM-DD HH:MM') : $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b> {{$t('date_of_arrival_at_the_border')}}:</b> {{ detailsItems.date_of_arrival_at_border ? $moment(detailsItems.date_of_arrival_at_border).format('YYYY-MM-DD HH:MM') :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b> {{$t('arrival_date_at_destination')}}:</b> {{ detailsItems.arrival_date_at_destination ? $moment(detailsItems.arrival_date_at_destination).format('YYYY-MM-DD HH:MM') :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b> {{$t('distance_to_destination')}}:</b> {{ detailsItems.distance_to_destination ? detailsItems.distance_to_destination :  $t('no_data')  }}</v-col>
                        </v-col>

                        <v-col class="py-0" cols="12" sm="2">

                        </v-col>
                        <v-col class="py-0" cols="12" sm="8">
                            <template>
                                <v-timeline
                                    align-top
                                    :dense="$vuetify.breakpoint.smAndDown"
                                >
                                    <v-timeline-item
                                        v-for="(item, i) in detailsItems.trackings"
                                        :key="i"
                                        color="primary"
                                        icon="mdi-chevron-down-circle-outline"
                                        fill-dot
                                        flat
                                    >
                                        <v-card
                                            color="primary"
                                            dark
                                        >
                                            <v-card-title class="title">
                                                {{$t('updated_at')}}  : {{  item.date_operation ? $moment(item.date_operation).format('YYYY-MM-DD HH:MM') : $t('no_data')}}
                                            </v-card-title>
                                            <v-card-text>
                                                <p style="line-height:25px">
                                                    <b>{{$t('distance_to_destination')}}:</b>  {{ item.distance_to_destination ? item.distance_to_destination :  $t('no_data')  }} <br/>
                                                    <b>{{$t('status')}}:</b> {{ item.status ? item.status :  $t('no_data')  }} <br/>
                                                    <b>{{$t('operations')}}:</b>  {{ item.station ? item.station :  $t('no_data')  }} <br/>
                                                    {{$t('date_operation')}}: {{  item.date_operation ? $moment(item.date_operation).format('YYYY-MM-DD HH:MM') : $t('no_data')}}
                                                </p>

                                            </v-card-text>
                                        </v-card>
                                    </v-timeline-item>
                                </v-timeline>
                            </template>
                        </v-col>
                        <v-col cols="12" sm="2">

                        </v-col>

                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-if="dialogFile" v-model="dialogFile"  width="500">

            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    {{ $t('upload_tracking') }}
                </v-card-title>

                <v-card-text class="my-2 py-2">
                    <div class="my-1">{{ $t('upload_title') }}</div>
                    <div class="my-1">{{ $t('upload_text_1') }}</div>
                    <div class="my-1">{{ $t('upload_text_2') }}</div>
                    <div class="my-1">{{ $t('upload_text_3') }}</div>
                    <div class="my-1">{{ $t('upload_text_4') }}</div>
                    <div class="my-2" v-html="$t('upload_text_attention')"></div>
                    <v-row class="my-2" align="center" justify="space-around">
                        <v-btn
                            class="my-3"
                            outlined
                            :href="fileDownload"
                            download>
                            {{ $t('download_example')}}
                        </v-btn>
                    </v-row>

                    <v-file-input class="my-2" v-model="uploadFile"
                                  accept=".xls,.xlsx"
                                  :label="$t('select_file')"
                                  truncate-length="11"
                    ></v-file-input>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="closeDialogFile()"
                    >
                        {{ $t('cancel')}}
                    </v-btn>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="saveFile()"
                    >
                        {{ $t('upload')}}
                    </v-btn>
                </v-card-actions>
            </v-card>


        </v-dialog>

        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="dialogCompanyToTracking"
        >
            <v-card>
                <v-toolbar
                    color="primary"
                    dark
                >{{ $t('add_company') }}</v-toolbar>
                <v-card-text>
                    <v-col cols="12">
                        <ValidationProvider ref="company" rules="min:1"
                                            v-slot="{ errors, valid }">
                            <v-autocomplete v-model="company" :items="companyItems"
                                            :error-messages="errors" :error="!valid"
                                            :search-input.sync="companySearching"
                                            item-text="name" item-value="id"
                                            prepend-icon="mdi-account"
                                            append-icon="mdi-location-enter"
                                            :loading="loadingCompanies" :disabled="loading"
                                            :no-data-text="companySearching ? $t('nothing_found_by',{'search': companySearching}) : $t('nothing_found_name')"
                                            :label="$t('company')"
                                            @click:clear="companyItems=[]"
                                            @click="clearCompanies"
                                            @click:append="redirectToCompany(company)"
                                            color="primary" clearable autocomplete="off">
                                <template v-slot:item="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.name"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.bin_iin"></span>
                                            </span>
                                </template>
                                <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.name"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.bin_iin"></span>
                                            </span>
                                </template>
                            </v-autocomplete>
                        </ValidationProvider>
                    </v-col>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn
                        text
                        @click="closeClientToTracking()"
                    >{{ $t('close') }}</v-btn>
                    <v-btn
                        text
                        @click="addCompanyToTracking()"
                    >{{ $t('add') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="dialogDeal"
            class="dialog_body"
        >
            <v-card>
                <v-toolbar class="dialog_toolbar" elevation="0">
                    <div class="ma-auto ml-0">
                        <span>{{ $t('add_deal') }}</span>
                    </div>
                    <div>
                        <v-icon @click="closeDealToApplication()" :title="$t('close')">mdi-close</v-icon>
                    </div>
                </v-toolbar>
                <v-card-text class="pa-2 pb-0">
                    <v-col cols="12">
                        <ValidationProvider ref="company" rules="min:1"
                                            v-slot="{ errors, valid }">
                            <v-autocomplete v-model="deal" :items="dealItems"
                                            :error-messages="errors" :error="!valid"
                                            :search-input.sync="dealSearching"
                                            item-text="number" item-value="id"
                                            append-icon="mdi-location-enter"
                                            :loading="loadingDeals" :disabled="loading"
                                            :no-data-text="dealSearching ? $t('nothing_found_by',{'search': dealSearching}) : $t('nothing_found_name')"
                                            :label="$t('deal.self')"
                                            @click:clear="dealItems=[]"
                                            @click="clearDeals"
                                            clearable autocomplete="off"
                                            outlined
                                            solo
                                            flat
                                            dense
                                            hide-details
                                            :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                            color="primary"
                                            class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                            >
                                <template v-slot:item="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.number"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.admin.name"></span>
                                            </span>
                                </template>
                                <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.number"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.admin.name"></span>
                                            </span>
                                </template>
                            </v-autocomplete>
                        </ValidationProvider>
                    </v-col>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="addDealApplication()"
                    >{{ $t('add') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="dialogTracking"
            class="dialog_body"
        >
            <v-card>
                <v-toolbar class="dialog_toolbar" elevation="0">
                    <div class="ma-auto ml-0">
                        <span>{{ $t('tracking') }}</span>
                    </div>
                    <div>
                        <v-icon @click="closeDialogTracking()" :title="$t('close')">mdi-close</v-icon>
                    </div>
                </v-toolbar>
                <v-card-text class="py-5">
                    <v-row >
                        <v-col class="py-0" cols="12" sm="6">
                            <ValidationProvider ref="departure" rules="min:1" v-slot="{ errors, valid }">
                                <div class="input_label">{{$t('station')}}</div>
                                <v-autocomplete v-model="station" :items="stationItems"
                                                :error="!valid" :error-messages="errors"
                                                :search-input.sync="stationSearching"
                                                item-text="geocoder_name" item-value="id"
                                                :loading="loadingDepartures"
                                                @click="clearStation"
                                                :no-data-text="stationSearching ? $t('nothing_found_by',{'search': stationSearching}) : $t('nothing_found_departures')"
                                                :label="$t('station')" @click:clear="stationItems=[]"
                                                return-object  autocomplete="off"
                                                outlined
                                                solo
                                                flat
                                                hide-details
                                                dense
                                                :disabled="loadingStation"
                                                :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                color="primary"
                                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                clearable>
                                </v-autocomplete>
                            </ValidationProvider>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="6">
                            <ValidationProvider ref="distance" rules="min:1"
                                                v-slot="{ errors, valid }">
                                <div class="input_label">{{$t('distance')}}</div>
                                <v-text-field v-model="distance" type="text"
                                              :error="!valid"
                                              :label="$t('distance')"
                                              hide-details
                                              :error-messages="errors"
                                              :disabled="loading"
                                              outlined
                                              solo
                                              flat
                                              dense
                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                              color="primary"
                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                              clearable>
                                </v-text-field>
                            </ValidationProvider>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="6">
                            <ValidationProvider ref="type_container" rules="min:1"
                                                v-slot="{ errors, valid }">
                                <div class="input_label">{{$t('type_container')}}</div>
                                <v-autocomplete v-model="type_container" :items="typeContainerItems"
                                                item-text="name" item-value="id"
                                                :label="$t('type_container')"
                                                :error="!valid"
                                                hide-details
                                                :error-messages="errors"
                                                outlined
                                                solo
                                                flat
                                                dense
                                                :disabled="loadingTypeContainer"
                                                :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                color="primary"
                                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                clearable></v-autocomplete>
                            </ValidationProvider>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="6">
                            <ValidationProvider ref="container_number" rules="min:2|max:255"
                                                v-slot="{ errors, valid }">
                                <div class="input_label">{{$t('container_number')}}</div>
                                <v-text-field v-model="container_number" type="text"
                                              :error="!valid"
                                              :label="$t('container_number')"
                                              hide-details
                                              :error-messages="errors"
                                              :disabled="loading"
                                              outlined
                                              solo
                                              flat
                                              dense
                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                              color="primary"
                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                              clearable>
                                </v-text-field>
                            </ValidationProvider>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="6">
                            <ValidationProvider ref="status" rules="min:1"
                                                v-slot="{ errors, valid }">
                                <div class="input_label">{{$t('status')}}</div>
                                <v-autocomplete v-model="status" :items="statusItems"
                                                item-text="code" item-value="id"
                                                :label="$t('status')"
                                                :error="!valid"
                                                hide-details
                                                :error-messages="errors"
                                                outlined
                                                solo
                                                flat
                                                dense
                                                :disabled="loading"
                                                :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                color="primary"
                                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                clearable
                                                @change="getSubStatus()"
                                                @click:clear="status=null;sub_status=null;subStatusItems=[];"
                                ></v-autocomplete>
                            </ValidationProvider>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="6">
                            <ValidationProvider ref="sub_status" rules="min:1"
                                                v-slot="{ errors, valid }">
                                <div class="input_label">{{$t('sub_status')}}</div>
                                <v-autocomplete v-model="sub_status" :items="subStatusItems"
                                                item-text="name" item-value="id"
                                                :label="$t('sub_status')"
                                                :error="!valid"
                                                hide-details
                                                :error-messages="errors"
                                                outlined
                                                solo
                                                flat
                                                dense
                                                :disabled="loading"
                                                :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                color="primary"
                                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                clearable
                                ></v-autocomplete>
                            </ValidationProvider>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="6">
                            <v-menu v-model="menuDateSending" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <ValidationProvider ref="date_sending" name="date_sending" rules="date_format"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('date_sending')}}</div>

                                        <v-text-field v-model="date_sending" :error-messages="errors"
                                                      :label="$t('date_sending')"
                                                      readonly v-on="on"
                                                      @click:clear="date_sending = null"
                                                      outlined
                                                      hide-details
                                                      solo
                                                      flat
                                                      dense
                                                      :disabled="loading"
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable></v-text-field>
                                    </ValidationProvider>
                                </template>
                                <v-date-picker ref="picker" v-model="date_sending"
                                               :min="$moment().format('YYYY-MM-DD')"
                                               :locale="lang" @change="menuDateSending = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="6">
                            <v-menu v-model="dateMenuPr" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <div class="input_label">{{$t('arrival_date_at_destination')}}</div>
                                    <ValidationProvider ref="date_pr" name="date__pr" rules="date_format"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="date_pr" :error-messages="errors"
                                                      :label="$t('arrival_date_at_destination')"
                                                      readonly v-on="on"
                                                      @click:clear="date_pr = null"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      hide-details
                                                      :disabled="loading"
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable></v-text-field>
                                    </ValidationProvider>
                                </template>
                                <v-date-picker ref="picker" v-model="date_pr"
                                               :min="$moment().format('YYYY-MM-DD')"
                                               :locale="lang" @change="dateMenuPr = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="6">
                            <v-menu v-model="dateMenuGr" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <ValidationProvider ref="date_gr" name="date_gr" rules="date_format"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('date_of_arrival_at_the_border')}}</div>

                                        <v-text-field v-model="date_gr" :error-messages="errors"
                                                      :label="$t('date_of_arrival_at_the_border')"
                                                      readonly v-on="on"
                                                      @click:clear="date_gr = null"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      hide-details
                                                      :disabled="loading"
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable></v-text-field>
                                    </ValidationProvider>
                                </template>
                                <v-date-picker ref="picker" v-model="date_gr"
                                               :min="$moment().format('YYYY-MM-DD')"
                                               :locale="lang" @change="dateMenuGr = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col class="py-0" cols="12" sm="6">
                            <v-menu v-model="dateMenuBorder" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <div class="input_label">{{$t('date_of_exit_from_border')}}</div>

                                    <ValidationProvider ref="date_border" name="date_from" rules="date_format"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="date_border" :error-messages="errors"
                                                      :label="$t('date_of_exit_from_border')"
                                                      readonly v-on="on"
                                                      @click:clear="date_border = null"
                                                      outlined
                                                      hide-details
                                                      solo
                                                      flat
                                                      dense
                                                      :disabled="loading"
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable></v-text-field>
                                    </ValidationProvider>
                                </template>
                                <v-date-picker ref="picker" v-model="date_border"
                                               :min="$moment().format('YYYY-MM-DD')"
                                               :locale="lang" @change="dateMenuBorder = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="saveTracking()"
                    >{{ $t('save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog
            v-model="dialogDetailTracking"
            :width="!$vuetify.breakpoint.xsOnly ? 700 : '100%'"
            overlay-color="#edebeb"
            :content-class="!$vuetify.breakpoint.xsOnly ? 'rounded-lg border1_B3B3B3' : ''"
            transition="dialog-bottom-transition"

            :fullscreen="$vuetify.breakpoint.xsOnly"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :scrollable="$vuetify.breakpoint.xsOnly"

        >
            <v-card>
                <v-toolbar class="dialog_toolbar" elevation="0">
                    <div class="ma-auto ml-0">
                        <span>{{ $t('tracking_data') }}</span>
                    </div>
                    <div>
                        <v-icon @click="close_info()">mdi-close</v-icon>
                    </div>
                </v-toolbar>
                <v-card-title class="deal_dialog_padding">
                    <v-row class="mx-0 px-0 py-0 my-0">
                        <v-col cols="12" class="mx-0 px-0 py-0 my-0">
                            <div class="text_left">
                                <v-chip v-for="(item, z) in data_info" :key="'data_info'+z"
                                        small
                                        class="mr-2 font-size-11"
                                        :class="item.class"
                                        :color="item.color"
                                        label
                                        outlined
                                        @click="show_info(item.type)"
                                >{{ item.name }}
                                </v-chip>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text class="mx-0 px-0 py-0 my-0">
                    <v-row class="mx-0 px-0 py-0 my-0">
                        <v-col cols="12" class="deal_dialog_padding pt-0" v-if="info_type==='data'">
                            <v-row class="mx-0 px-0 py-0 my-0">
                                <v-col cols="12" md="6" class="mx-0 px-0 py-0 my-0">
                                    <v-row class="mx-0 px-0 py-0 my-0">
                                        <v-col cols="12" class="deal_in_detail"><b>{{ $t('application_number') }}:</b> {{ detail_tracking.number ? detail_tracking.number : $t('no_data') }}</v-col>
                                        <v-col cols="12" class="deal_in_detail"><b>{{ $t('container_number') }}:</b> {{ detail_tracking.container_number ? detail_tracking.container_number : $t('no_data') }}</v-col>
                                        <v-col cols="12" class="deal_in_detail"><b>{{ $t('departures') }}:</b> {{ detail_tracking.departure ? detail_tracking.departure : $t('no_data') }}</v-col>
                                        <v-col cols="12" class="deal_in_detail"><b>{{ $t('destination') }}:</b> {{ detail_tracking.destination ? detail_tracking.destination : $t('no_data') }}</v-col>
                                        <v-col cols="12" class="deal_in_detail"><b>{{ $t('type_container') }}:</b> {{ detail_tracking.type_container ? detail_tracking.type_container : $t('no_data') }}</v-col>
                                        <v-col cols="12" class="deal_in_detail"><b>{{ $t('type_transportation') }}:</b> {{ detail_tracking.type_transport ? detail_tracking.type_transport : $t('no_data') }}</v-col>
                                        <v-col cols="12" class="deal_in_detail"><b>{{ $t('download_type') }}:</b>
                                            {{ (detail_tracking.loading_type && detail_tracking.loading_type.name) ? detail_tracking.loading_type.code + ' ' + detail_tracking.loading_type.name : $t('no_data') }}
                                        </v-col>
                                        <v-col cols="12" class="deal_in_detail"><b>{{ $t('application_date') }}:</b> {{ detail_tracking.created_at ? $moment(detail_tracking.created_at).format('YYYY-MM-DD HH:mm') : $t('no_data') }}</v-col>

                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-row>
                                        <v-col cols="12" md="6" lg="12" class="deal_in_detail"><b>{{ $t('platform_number') }}:</b> {{ detail_tracking.platform_number ? detail_tracking.platform_number : $t('no_data') }}</v-col>
                                        <v-col cols="12" md="6" lg="12" class="deal_in_detail"><b>{{ $t('platform_number_knr') }}:</b> {{ detail_tracking.platform_number_knr ? detail_tracking.platform_number_knr : $t('no_data') }}</v-col>
                                        <v-col cols="12" class="deal_in_detail"><b>{{ $t('date_operation') }}:</b> {{ detail_tracking.date_operation ? $moment(detail_tracking.date_operation).format('YYYY-MM-DD HH:mm') : $t('no_data') }}</v-col>
                                        <v-col cols="12" class="deal_in_detail"><b>{{ $t('date_of_arrival_at_the_border') }}:</b>
                                            {{ detail_tracking.date_of_arrival_at_border ? $moment(detail_tracking.date_of_arrival_at_border).format('YYYY-MM-DD HH:mm') : $t('no_data') }}
                                        </v-col>
                                        <v-col cols="12" class="deal_in_detail"><b>{{ $t('arrival_date_at_destination') }}:</b>
                                            {{ detail_tracking.arrival_date_at_destination ? $moment(detail_tracking.arrival_date_at_destination).format('YYYY-MM-DD HH:mm') : $t('no_data') }}
                                        </v-col>
                                        <v-col cols="12" md="6" lg="12" class="deal_in_detail"><b>{{ $t('distance_to_destination') }}:</b> {{
                                                detail_tracking.distance_to_destination ? detail_tracking.distance_to_destination : $t('no_data')
                                            }}
                                        </v-col>
                                        <v-col cols="12" md="6" lg="12" class="deal_in_detail"><b>{{ $t('delivered') }}:</b> {{ detail_tracking.paid == true ? $t('yes') : $t('no') }}</v-col>

                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" v-if="info_type==='tracking'">
                            <v-row>
                                <v-col cols="12" class="rounded-lg" style="background-color: #CDDAE1">
                                    <v-row>
                                        <v-col cols="12" md="4" >
                                            <div class="cursor-pointer" @click="addOperationTracking(detail_tracking.uuid)">{{$t('add_tracking')}}</div>
                                            <ul class="station">
                                                <li v-if="tracking_geo.from">
                                                    <div class="node blue_point"></div>
                                                    <p class="font-primary text_left">{{ tracking_geo.from.name }} <span>{{ tracking_geo.from.date_operation }}</span></p>
                                                </li>
                                                <li>
                                                    <div class="divider blue_line"></div>
                                                </li>

                                                <template v-for="(item, t) in tracking_geo.tracking">
                                                    <li :key="'geo'+item.id" class="text_left">
                                                        <div class="node blue_point"></div>
                                                        <p class="font-primary text_left">{{ item.name }}<span>{{ item.date_operation }}</span></p>
                                                    </li>
                                                    <li :key="'geo'+item.name">
                                                        <div :class="(t+1)===tracking_geo.tracking.length ? 'grey_line' : 'blue_line'" class="divider"></div>
                                                    </li>
                                                </template>


                                                <li v-if="tracking_geo.to">
                                                    <div class="node grey_point"></div>
                                                    <p class="font-primary text_left">{{ tracking_geo.to.name }} <span>{{ tracking_geo.to.date_operation }}</span></p>
                                                </li>

                                            </ul>

                                        </v-col>
                                        <v-col cols="12" md="8">
                                            <div style="height: 310px;" class="rounded-lg my-0 py-0">
                                                <yandex-map class="py-0 my-0 py-0" v-if="coords && showMap" ref="map"
                                                            :coords="coords"
                                                            :controls="[]"
                                                            :show-all-markers="showAllMarkers"
                                                            :zoom="zoom"
                                                            @map-was-initialized="initHandler"
                                                            style="background-color: #ffffff">
                                                </yandex-map>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>

            </v-card>
        </v-dialog>

    </v-container>
</template>
<script>

    import {mapGetters} from "vuex"
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import debounce from "lodash/debounce";
    import PageLayout from "@/components/Leentech/PageLayout.vue";
    import TabsNav from "@/components/Leentech/TabsNav.vue";
    import FilterView from "@/plugins/mixins/FilterView";
    import {loadYmap, yandexMap, ymapMarker} from 'vue-yandex-maps'

    export default {
        name: "Trackings",
        components: {
            TabsNav,
            ValidationProvider,
            ValidationObserver,
            PageLayout,
            yandexMap,

        },
        data() {
            return {
                disabledCount: 0,
                deal: null,
                dealItems: [],
                dealSearching: null,
                loadingDeals: false,
                dialogDeal: false,

                uploadFile: null,
                fileDownload: null,
                dialogFile: false,
                textFileError: null,
                dialogFileError: false,
                dialogDetails: false,
                company: null,
                companyItems: [],
                companySearching: null,
                loadingCompanies: false,


                tracking: null,
                trackings: [],
                trackingItems: [],
                sortBy: "id",
                sortDir: false,
                page: 1,
                pageCount: 0,
                perPage: 5,
                totalVisiblePag: 7,
                totalTrackings: 0,
                options: {
                    ...FilterView.data().options,
                    sortBy: ['id']
                },
                loading: false,
                singleSelect: false,
                dialogCompanyToTracking: false,
                selected: [],
                headers: [
                    {
                        text: this.$t('date_of_deployment'),
                        align: "left",
                        value: "date_operation"
                    },
                    {
                        text: this.$t('number_deal'),
                        align: "left",
                        value: "number_deal"
                    },
                    {
                        text: this.$t('container_number'),
                        align: "left",
                        value: "container_number"
                    },
                    {
                        text: this.$t('station'),
                        align: "left",
                        value: "station"
                    },

                    {
                        text: this.$t('departures'),
                        align: "left",
                        value: "departure"
                    },
                    {
                        text: this.$t('destination'),
                        align: "left",
                        value: "destination"
                    },
                ],
                number: null,
                platform_number: null,
                platform_number_knr: null,
                filterShow: false,
                detailsItems:[],
                dateFrom: null,
                dateMenuFrom: false,
                dateTo: null,
                dateMenuTo: false,
                dateFromGr: null,
                dateMenuFromGr: false,
                dateToGr: null,
                dateMenuToGr: false,
                dateFromPr: null,
                dateMenuFromPr: false,
                dateToPr: null,
                dateMenuToPr: false,
                application_number: null,
                container_number: null,
                destination: null,
                destinationItems:[],
                destinationSearching: null,
                loadingDestination: false,
                loadingDelivered: false,
                delivered: null,
                departure: null,
                departureItems:[],
                departureSearching: null,
                loadingDepartures: false,
                station: null,
                stationItems:[],
                stationSearching: null,
                loadingStation: false,
                type_container: null,
                typeContainerItems:[],
                loadingTypeContainer: null,
                status: null,
                statusItems:[],
                loadingStatuses: false,
                dateOperationFrom:null,
                menuDateOfDeploymentFrom: false,
                dateOperationTo:null,
                menuDateOfDeploymentTo: false,
                deliveredItems:[
                    {
                        'id': -1,
                        'name': this.$t('no')
                    },
                    {
                        'id': 1,
                        'name': this.$t('yes')
                    },
                ],
                system_filters: [
                    {'title': 'Все контакты', 'count': '10'},
                    {'title': 'Мои клиенты', 'count': '0'},
                    {'title': 'Избранное', 'count': '7'},
                ],
                Additional: [],
                group1: true,
                group2: true,
                filter_show: false,
                dialogTracking: false,
                date_border: null,
                dateMenuBorder: false,
                date_gr: null,
                dateMenuGr: false,
                date_pr: null,
                dateMenuPr: false,
                date_sending:null,
                menuDateSending: false,
                distance: null,
                sub_status: null,
                subStatusItems:[],
                tracking_uuid:null,
                dialogDetailTracking: false,
                detail_tracking: {},
                data_info: [
                    {
                        "type": "data",
                        "name" : this.$t('data'),
                        "class": "mr-2",
                        "color": "primary",
                    },
                    {
                        "type": "tracking",
                        "name": this.$t('tracking'),
                        "class": "border_0",
                        "color": "color_B3B3B3"
                    }
                ],
                info_type: "data",
                tracking_geo: [],
                referencePoints: [],
                showAllMarkers: true,
                mapMarker: null,
                zoom: 12,
                ymaps: null,
                coordinates: [],
                showMap: false,


            };
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
            filteredHeaders() {
                return this.headers.filter(h => !h.hide)
            },
            coords: {
                get: function () {
                    return (this.coordinates && this.coordinates.length === 2) ? this.coordinates : [51, 71]
                },
                set: function (val) {
                    this.coordinates = (val && val.length === 2) ? val : [51, 71]
                }
            },
            mapSettings() {
                this.$yandex.lang = 'ru_RU'
                return this.$yandex
            },
        },
        mounted() {
            this.options.itemsPerPage = this.perPage = this.itemsPerPage
            this.getStatus()
            this.getTypeContainer()
            this.getTrackings();
        },
        watch: {
            options: {
                handler() {
                    this.getTrackings()
                },
                deep: false
            },
            companySearching: debounce(function (val) {
                if (val && !this.company) {
                    this.getCompanies(val)
                }
            }, 500),
            departureSearching: debounce(function (val) {
                if (val && !this.departure) {
                    this.getDepartures(val)
                }
            }, 500),
            stationSearching: debounce(function (val) {
                if (val && !this.station) {
                    this.getStations(val)
                }
            }, 500),
            destinationSearching: debounce(function (val) {
                if (val && !this.destination) {
                    this.getDestination(val)
                }
            }, 500),
            dealSearching: debounce(function (val) {
                if (val) {
                    this.getDeals(val)
                }
            }, 500),
        },
        methods: {
            rowClass(){
                return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table6' : 'rowMobiClass';
            },
            showDeal(uuid) {
                this.$router.push({
                    name: 'deal.show',
                    params: {
                        id: uuid
                    }
                })
            },
            addTracking(uuid) {
                this.$router.push({
                    name: 'tracking.create',
                })
            },

            async getTracking(uuid) {
                var _this = this;
                this.progress = 0
                this.loading = true
                let params = {}
                if (this.language) {
                    params.language = this.language
                }
                await this.$http
                    .get(`admin/tracking/${uuid}`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.tracking_uuid = res.body.data.uuid
                        this.container_number = res.body.data.container_number
                        this.platform_number = res.body.data.platform_number
                        this.platform_number_knr = res.body.data.platform_number_knr
                        this.date_sending = res.body.data.date_sending ? this.$moment(res.body.data.date_sending).format('YYYY-MM-DD') : null;
                        this.date_border = res.body.data.date_border ? this.$moment(res.body.data.date_border).format('YYYY-MM-DD') : null;
                        this.date_gr = res.body.data.date_gr ? this.$moment(res.body.data.date_gr).format('YYYY-MM-DD') : null;
                        this.date_pr = res.body.data.date_pr ? this.$moment(res.body.data.date_pr).format('YYYY-MM-DD') : null;
                        this.distance = res.body.data.distance
                        this.station = (res.body.data.station && res.body.data.station.id) ? res.body.data.station.id : null;
                        this.stationItems = [res.body.data.station]
                        this.type_container = (res.body.data.type_container && res.body.data.type_container.id) ? res.body.data.type_container.id : null
                        this.loading_type = (res.body.data.loading_type && res.body.data.loading_type.id) ? res.body.data.loading_type.id : null;
                        this.type_transport = (res.body.data.type_transport && res.body.data.type_transport.id) ? res.body.data.type_transport.id : null
                        this.status = (res.body.data.status && res.body.data.status.id) ? res.body.data.status.id : null;
                        this.sub_status = (res.body.data.sub_status && res.body.data.sub_status.id) ? res.body.data.sub_status.id : null
                        this.subStatusItems = [res.body.data.sub_status]
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_tracking'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async saveTracking() {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.languages && this.languages[this.tab]) {
                    formData.append('language', this.languages[this.tab])
                }
                if (this.container_number) {
                    formData.append('container_number', this.container_number)
                }
                if (this.platform_number) {
                    formData.append('platform_number', this.platform_number)
                }
                if (this.platform_number_knr) {
                    formData.append('platform_number_knr', this.platform_number_knr)
                }
                if (this.date_sending) {
                    formData.append('date_sending', this.date_sending)
                }
                if (this.date_border) {
                    formData.append('date_border', this.date_border)
                }
                if (this.date_gr) {
                    formData.append('date_gr', this.date_gr)
                }
                if (this.date_pr) {
                    formData.append('date_pr', this.date_pr)
                }
                if (this.distance) {
                    formData.append('distance', this.distance)
                }

                if (this.station) {
                    if (this.station.id) {
                        formData.append('station', this.station.id)
                    } else {
                        formData.append('station', this.station)
                    }
                }
                if (this.type_container) {
                    if (this.type_container.id) {
                        formData.append('type_container', this.type_container.id)
                    } else {
                        formData.append('type_container', this.type_container)
                    }
                }
                if (this.loading_type) {
                    if (this.loading_type.id) {
                        formData.append('loading_type', this.loading_type.id)
                    } else {
                        formData.append('loading_type', this.loading_type)
                    }
                }
                if (this.type_transport) {
                    if (this.type_transport.id) {
                        formData.append('type_transport', this.type_transport.id)
                    } else {
                        formData.append('type_transport', this.type_transport)
                    }
                }
                if (this.status) {
                    if (this.status.id) {
                        formData.append('status', this.status.id)
                    } else {
                        formData.append('status', this.status)
                    }
                }
                if (this.sub_status) {
                    if (this.sub_status.id) {
                        formData.append('sub_status', this.sub_status.id)
                    } else {
                        formData.append('sub_status', this.sub_status)
                    }
                }

                if (this.tracking_uuid ) {
                    await this.$http
                        .put(`admin/tracking_station/${this.tracking_uuid}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('tracking_has_been_updated'))
                            this.closeDialogTracking();
                            this.getTrackings();

                            //this.forceRerender()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('tracking_has_not_been_updated'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
            },
            showDialogTracking(item){
                this.getTracking(item.uuid)
                this.dialogTracking = true;
            },
            closeDialogTracking(){
                this.dialogTracking = false;
                this.tracking_uuid = null;
                this.container_number = null;
                this.platform_number = null;
                this.platform_number_knr = null;
                this.date_sending = null;
                this.date_border = null;
                this.date_gr = null;
                this.date_pr = null;
                this.distance = null;
                this.station = null;
                this.stationItems = [];
                this.type_container = null;
                this.loading_type = null;
                this.type_transport = null;
                this.status =  null;
                this.sub_status =  null;
                this.subStatusItems = []

            },
            editApplication(item){
                this.$router.push({
                    name: 'tracking.edit',
                    params: {
                        uuid: item.uuid
                    }
                })
            },
            selectAllToggle(props) {
                if(this.selected.length !== (this.topics.length - this.disabledCount)) {
                    this.selected = [];
                    const self = this;
                    props.items.forEach(item => {
                        if(!item.number_deal) {
                            self.selected.push(item);
                        }
                    });
                } else this.selected = [];
            },
            async getDeals(str) {
                this.loadingDeal = true
                let params = {};
                params.filter = 'not_tracking'
                if (str !== 'undefined') {
                    params.number = str;
                }

                await this.$http
                    .get("admin/deal", {
                        params: params,
                    })
                    .then(res => {
                        this.dealItems = res.body.data;
                    })
                    .catch(err => {
                        this.dealItems = []
                        this.$toastr.error(this.$t('failed_to_get_deals'))
                    })
                    .finally(end => {
                        this.loadingDeal = false
                    })
            },
            clearDeals() {
                if (!this.deal) {
                    this.dealItems = []
                }
            },
            closeDealToApplication(){
                this.dialogDeal  = false
                this.deal = null
                this.dealItems = []
                this.dealSearching = null

            },
            async addDealApplication(){
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                this.selected.forEach(function callback(item, key) {
                    formData.append(`applications_uuid[${key}]`, item.uuid)
                });

                if (this.deal ) {
                    formData.append('deal', this.deal )
                }
                // Add
                await this.$http
                    .post('admin/tracking/deal', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('deal_has_been_updated'))
                        this.closeDealToApplication();
                        this.selected = [];
                        this.getTrackings()
                    })
                    .catch(err => {
                        this.closeDealToApplication();
                        this.$toastr.error(this.$t('deal_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })


            },
            openDialogDealApplication(){
                this.deal = null
                this.dealItems =  []
                this.dealSearching = null
                this.dialogDeal   = true
            },

            cancelDetails(){
                this.dialogDetails = false;
            },

            showDetailsApplication(item) {
                this.detail_tracking = {};
                this.detail_tracking = item;
                this.dialogDetailTracking = true;
                console.log(this.detail_tracking);
            },
            close_info() {
                this.detail_tracking = {};
                this.dialogDetailTracking = false;
            },
            show_info(type) {
                this.info_type = type;
                if (type === 'tracking') {

                    let mclass = this.data_info[0].class;
                    let mcolor = this.data_info[0].color;

                    this.data_info[0].class = this.data_info[1].class
                    this.data_info[0].color = this.data_info[1].color

                    this.data_info[1].class = mclass;
                    this.data_info[1].color = mcolor;

                    this.getTrackingDeal()
                } else if (this.data_info[0].color !== 'primary') {
                    let mclass = this.data_info[1].class;
                    let mcolor = this.data_info[1].color;

                    this.data_info[1].class = this.data_info[0].class
                    this.data_info[1].color = this.data_info[0].color

                    this.data_info[0].class = mclass;
                    this.data_info[0].color = mcolor;
                }

            },
            async getTrackingDeal() {
                var _this = this;
                this.loading = true;

                let params = {};


                await this.$http
                    .get("admin/tracking/deal/" + this.detail_tracking.deal_uuid, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.referencePoints = []
                        this.tracking_geo = res.body.data.tracking[0];
                        this.coordinates = this.tracking_geo.from.point

                        this.referencePoints.push(this.tracking_geo.from.point);
                        if (this.tracking_geo && this.tracking_geo.tracking) {
                            for (var i = 0; i < this.tracking_geo.tracking.length; i++) {
                                this.referencePoints.push(this.tracking_geo.tracking[i].point)
                            }
                        }
                        console.log(this.referencePoints);

                        this.ymapInit()
                    })
                    .catch(err => {
                        this.tracking_geo = []
                        this.$toastr.error(this.$t('failed_to_get_list_tracking'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async ymapInit() {
                var _this = this
                if (window.ymaps) {
                    this.showMap = false
                    if (this.$refs.map && this.$refs.map.myMap) {
                        this.$refs.map.myMap.destroy()
                    }
                    document.getElementById("vue-yandex-maps").remove()
                    delete window.ymaps
                }
                await loadYmap({...this.mapSettings, debug: true})
                if (window.ymaps) {
                    window.ymaps.ready(function () {
                        _this.showMap = true
                    })
                }
            },
            initHandler(e) {
                var _this = this;
                console.log(this.coords.length)
                if (this.coords && this.coords.length !== 2 && window.ymaps) {
                    console.log('dsffsdfsdfsdfsdfsdf')
                    var location = window.ymaps.geolocation.get({
                        provider: 'yandex',
                        mapStateAutoApply: true,
                        autoReverseGeocode: true
                    })
                    location.then(
                        function (result) {
                            //console.log(result.geoObjects.get(0).geometry.getCoordinates())
                            e.geoObjects.add(result.geoObjects)
                        },
                        function (err) {
                            console.log('Error: ' + err)
                        }
                    )
                }
                e.geoObjects.options.set('draggable', true)
                _this.myMap = e;
                var multiRoute2 = new window.ymaps.multiRouter.MultiRoute({
                    // Точки маршрута. Точки могут быть заданы как координатами, так и адресом.
                    referencePoints: this.referencePoints,
                }, {
                    // Внешний вид путевых точек.
                    wayPointStartIconColor: "#FFFFFF",
                    wayPointStartIconFillColor: "#B3B3B3",
                    // Внешний вид линии активного маршрута.
                    routeActiveStrokeWidth: 4,
                    routeActiveStrokeStyle: 'solid',
                    routeActiveStrokeColor: "#007EC9",
                    // Внешний вид линий альтернативных маршрутов.
                    routeStrokeStyle: 'dot',
                    routeStrokeWidth: 3,
                    // Автоматически устанавливать границы карты так,
                    // чтобы маршрут был виден целиком.
                    boundsAutoApply: true
                });


                _this.myMap.geoObjects.add(multiRoute2);
                //e.geoObjects.add(multiRoute);

                var fullscreenControl = new window.ymaps.control.FullscreenControl();
                _this.myMap.controls.add(fullscreenControl);
                //  e.controls.add('FullscreenControl');
            },
            addOperationTracking(uuid) {
                this.$router.push({
                    name: 'tracking_operation.create',
                    params: {
                        uuid: uuid
                    }
                })
            },
            async getStatus() {
                this.loadingStatuses = true
                let params = {};
                params.filter = 'search'

                await this.$http
                    .get("admin/status", {
                        params: params,
                    })
                    .then(res => {
                        this.statusItems = res.body.data;
                    })
                    .catch(err => {
                        this.statusItems = []
                        this.$toastr.error(this.$t('failed_to_get_status'))
                    })
                    .finally(end => {
                        this.loadingStatuses = false
                    })
            },
            async getTypeContainer() {
                this.loadingTypeContainer = true
                let params = {};
                params.filter = 'search'

                await this.$http
                    .get("admin/type_container", {
                        params: params,
                    })
                    .then(res => {
                        this.typeContainerItems = res.body.data;
                    })
                    .catch(err => {
                        this.typeContainerItems = []
                        this.$toastr.error(this.$t('failed_to_get_type_container'))
                    })
                    .finally(end => {
                        this.loadingTypeContainer = false
                    })
            },
            async getDepartures(str) {
                this.loadingDepartures = true
                let params = {};
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.station_name = str;
                }

                await this.$http
                    .get("admin/station", {
                        params: params,
                    })
                    .then(res => {
                        this.departureItems = res.body.data;
                    })
                    .catch(err => {
                        this.departureItems = []
                        this.$toastr.error(this.$t('failed_to_get_station'))
                    })
                    .finally(end => {
                        this.loadingDepartures = false
                    })
            },

            clearDepartures() {
                if (!this.departure) {
                    this.departuresItems = []
                }
            },
            async getDestination(str) {
                this.loadingDestination = true
                let params = {};
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.station_name = str;
                }

                await this.$http
                    .get("admin/station", {
                        params: params,
                    })
                    .then(res => {
                        this.destinationItems = res.body.data;
                    })
                    .catch(err => {
                        this.destinationItems = []
                        this.$toastr.error(this.$t('failed_to_get_station'))
                    })
                    .finally(end => {
                        this.loadingDestination = false
                    })
            },
            clearDestination() {
                if (!this.destination) {
                    this.destinationItems = []
                }
            },

            async getStations(str) {
                this.loadingStations = true
                let params = {};
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.station_name = str;
                }

                await this.$http
                    .get("admin/station", {
                        params: params,
                    })
                    .then(res => {
                        this.stationItems = res.body.data;
                    })
                    .catch(err => {
                        this.stationItems = []
                        this.$toastr.error(this.$t('failed_to_get_station'))
                    })
                    .finally(end => {
                        this.loadingStations = false
                    })
            },
            clearStation() {
                if (!this.station) {
                    this.stationItems = []
                }
            },
            async getSubStatus() {
                if(this.status && this.status > 0){
                    this.loadingSubStatuses = true
                    let params = {};
                    params.filter = 'search'
                    params.status_id = this.status

                    await this.$http
                        .get("admin/sub_status", {
                            params: params,
                        })
                        .then(res => {
                            this.subStatusItems = res.body.data;
                        })
                        .catch(err => {
                            this.subStatusItems = []
                            this.$toastr.error(this.$t('failed_to_get_sub_status'))
                        })
                        .finally(end => {
                            this.loadingSubStatuses = false
                        })
                }

            },

            async getTrackings() {
                console.log('dddd');
                this.loading = true;

                let params = {};

                    params.sortBy = 'id';


                    params.sortDir = 'desc';

                params.upload = -1

                    params.perPage = this.itemsPerPage;
                params.page = this.page

                if (this.tracking) {
                    params.tracking = this.tracking;
                }

                if (this.company) {
                    if (this.company.id) {
                        params.company = this.company.id
                    } else {
                        params.company = this.company
                    }
                }

                if (
                    this.dateFrom !== undefined &&
                    this.dateFrom != null &&
                    this.dateFrom !== ''
                ) {
                    params.dateFrom = this.dateFrom;
                }
                if (
                    this.dateTo !== undefined &&
                    this.dateTo != null &&
                    this.dateTo !== ''
                ) {
                    params.dateTo = this.dateTo;
                }

                if (
                    this.dateFromGr !== undefined &&
                    this.dateFromGr != null &&
                    this.dateFromGr !== ''
                ) {
                    params.date_of_arrival_at_border_from = this.dateFromGr;
                }
                if (
                    this.dateToGr !== undefined &&
                    this.dateToGr != null &&
                    this.dateToGr !== ''
                ) {
                    params.date_of_arrival_at_border_to = this.dateToGr;
                }

                if (
                    this.dateFromPr !== undefined &&
                    this.dateFromPr != null &&
                    this.dateFromPr !== ''
                ) {
                    params.arrival_date_at_destination_from = this.dateFromPr;
                }
                if (
                    this.dateToPr !== undefined &&
                    this.dateToPr != null &&
                    this.dateToPr !== ''
                ) {
                    params.arrival_date_at_destination_to = this.dateToPr;
                }


                if (
                    this.dateOperationFrom !== undefined &&
                    this.dateOperationFrom != null &&
                    this.dateOperationFrom !== ''
                ) {
                    params.date_operation_from = this.dateOperationFrom;
                }
                if (
                    this.dateOperationTo !== undefined &&
                    this.dateOperationTo != null &&
                    this.dateOperationTo !== ''
                ) {
                    params.date_operation_to = this.dateOperationTo;
                }


                if (this.delivered) {
                    if (this.delivered.id) {
                        params.delivered = this.delivered.id;
                    } else {
                        params.delivered = this.delivered;
                    }
                }
                if (this.status) {
                    if (this.status.id) {
                        params.status = this.status.id;
                    } else {
                        params.status = this.status;
                    }
                }

                if (this.type_container) {
                    if (this.type_container.id) {
                        params.type_container = this.type_container.id;
                    } else {
                        params.type_container = this.type_container;
                    }
                }

                if (this.number) {
                        params.container_number = this.number;
                }

                if (this.destination) {
                    if (this.destination.id) {
                        params.destination =  this.destination.id
                    } else {
                        params.destination =  this.destination
                    }
                }

                if (this.departure) {
                    if (this.departure.id) {
                        params.departure =  this.departure.id
                    } else {
                        params.departure =  this.departure
                    }
                }

                if (this.application_number) {
                    params.application_number = this.application_number;
                }

                if (this.platform_number_knr) {
                    params.platform_number_knr = this.platform_number_knr;
                }

                if (this.platform_number) {
                    params.platform_number = this.platform_number;
                }

                await this.$http
                    .get("admin/tracking", {
                        params: params,
                    })
                    .then(res => {
                        this.trackings = res.body.data
                        this.page = res.body.meta.current_page
                        this.totalTrackings = res.body.meta.total
                        this.pageCount = res.body.meta.last_page
                    })
                    .catch(err => {
                        this.trackings = []
                        this.totalTrackings = 0
                        this.$toastr.error(this.$t('failed_to_get_list_tracking'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async getCompanies(str) {
                if (str) {
                    this.loadingCompanies = true
                    let params = {}
                    params.filter = 'search'
                    if (str !== 'undefined') {
                        params.company = str
                    }
                    await this.$http
                        .get("admin/company", {
                            params: params,
                        })
                        .then(res => {
                            this.companyItems = res.body.data
                        })
                        .catch(err => {
                            this.companyItems = []
                        })
                        .finally(end => {
                            this.loadingCompanies = false
                        })
                }
            },
            clearCompanies() {
                if (!this.company) {
                    this.companyItems = []
                }
            },
            redirectToCompany(val) {
                if (val) {
                    this.$router.push({
                        name: 'company.profile',
                        params: {
                            id: val
                        }
                    })
                }
            },
            async UploadApplications() {
                this.loading = true;
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options;
                let params = {};
                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0];
                }
                if (sortDesc[0] !== undefined) {
                    params.sortDir = sortDesc[0] ? 'asc' : 'desc';
                }

                params.upload = 1

                if (itemsPerPage !== undefined) {
                    params.perPage = itemsPerPage;
                }
                if (this.tracking) {
                    params.tracking = this.tracking;
                }

                if (this.client) {
                    if (this.client.id) {
                        params.client = this.client.id
                    } else {
                        params.client = this.client
                    }
                }

                if (
                    this.dateFrom !== undefined &&
                    this.dateFrom != null &&
                    this.dateFrom !== ''
                ) {
                    params.dateFrom = this.dateFrom;
                }
                if (
                    this.dateTo !== undefined &&
                    this.dateTo != null &&
                    this.dateTo !== ''
                ) {
                    params.dateTo = this.dateTo;
                }

                if (
                    this.dateFromGr !== undefined &&
                    this.dateFromGr != null &&
                    this.dateFromGr !== ''
                ) {
                    params.date_of_arrival_at_border_from = this.dateFromGr;
                }
                if (
                    this.dateToGr !== undefined &&
                    this.dateToGr != null &&
                    this.dateToGr !== ''
                ) {
                    params.date_of_arrival_at_border_to = this.dateToGr;
                }

                if (
                    this.dateFromPr !== undefined &&
                    this.dateFromPr != null &&
                    this.dateFromPr !== ''
                ) {
                    params.arrival_date_at_destination_from = this.dateFromPr;
                }
                if (
                    this.dateToPr !== undefined &&
                    this.dateToPr != null &&
                    this.dateToPr !== ''
                ) {
                    params.arrival_date_at_destination_to = this.dateToPr;
                }


                if (
                    this.dateOperationFrom !== undefined &&
                    this.dateOperationFrom != null &&
                    this.dateOperationFrom !== ''
                ) {
                    params.date_operation_from = this.dateOperationFrom;
                }
                if (
                    this.dateOperationTo !== undefined &&
                    this.dateOperationTo != null &&
                    this.dateOperationTo !== ''
                ) {
                    params.date_operation_to = this.dateOperationTo;
                }


                if (this.delivered) {
                    if (this.delivered.id) {
                        params.delivered = this.delivered.id;
                    } else {
                        params.delivered = this.delivered;
                    }
                }
                if (this.status) {
                    if (this.status.id) {
                        params.status = this.status.id;
                    } else {
                        params.status = this.status;
                    }
                }

                if (this.type_container) {
                    if (this.type_container.id) {
                        params.type_container = this.type_container.id;
                    } else {
                        params.type_container = this.type_container;
                    }
                }

                if (this.number) {
                    params.container_number = this.number;
                }

                if (this.destination) {
                    if (this.destination.id) {
                        params.destination =  this.destination.id
                    } else {
                        params.destination =  this.destination
                    }
                }

                if (this.departure) {
                    if (this.departure.id) {
                        params.departure =  this.departure.id
                    } else {
                        params.departure =  this.departure
                    }
                }

                if (this.application_number) {
                    params.application_number = this.application_number;
                }

                if (this.platform_number_knr) {
                    params.platform_number_knr = this.platform_number_knr;
                }

                if (this.platform_number) {
                    params.platform_number = this.platform_number;
                }

                await this.$http
                    .get("admin/tracking", {
                        params: params,
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('file_was_uploaded'))
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_upload_file'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            openDialogFile() {
                this.dialogFile = true;
            },
            closeDialogFile() {
                this.uploadFile = null;
                this.dialogFile = false;
            },
            async saveFile() {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()


                if (this.uploadFile) {
                    formData.append('file', this.uploadFile)
                }

                // Add
                await this.$http
                    .post('admin/tracking/import', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('file_was_uploaded'))
                        this.dialogFile = false
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_upload_file'))
                        this.dialogFileError = true
                        this.textFileError = err.body.message;
                        /*
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                        */
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })

            },
            openDialogClientToTracking(){
                this.client = null
                this.companyItems =  []
                this.companySearching = null
                this.dialogCompanyToTracking  = true
            },
            closeClientToTracking(){
                this.client = null
                this.companyItems = []
                this.companySearching = null
                this.dialogCompanyToTracking  = false
            },
            async addCompanyToTracking(){
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                const itemsUuidTracking = [];
                this.selected.forEach(function callback(item, key) {
                     formData.append(`tracking_uuid[${key}]`, item.uuid)
                });

                if (this.company) {
                    formData.append('company', this.company)
                }
                // Add
                await this.$http
                    .post('admin/tracking/company', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('client_has_been_updated'))
                        this.closeClientToTracking();
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('client_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })


            }
        }
    }
</script>

<style>
.deal_col_50{
    width: 50% !important;
    max-width: 50% !important;
}
</style>
